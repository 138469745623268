import {
	type PageRequest,
	createPageRequest,
} from '@atlassian/jira-shared-types/src/rest/jira/page.tsx';
import { EXPAND_PARAMETERS } from '../../model/constants/expands.tsx';
import { SORT_FIELDS } from '../../model/constants/sort-field.tsx';
import { ITEMS_PER_PAGE } from '../../model/index.tsx';
import { getSortField, getSortDirection, getPage } from '../../state/selectors/query/index.tsx';
import type { State } from '../../state/types.tsx';

const getSort = (state: State) => ({
	direction: getSortDirection(state),
	field: SORT_FIELDS[getSortField(state)],
});

export const getServiceRequest = (state: State): PageRequest =>
	createPageRequest(getPage(state), ITEMS_PER_PAGE, getSort(state), EXPAND_PARAMETERS);
