import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { isLoading } from '../../../state/selectors/dashboards/index.tsx';
import type { State } from '../../../state/types.tsx';
import Error from './view.tsx';

const mapStateToProps = (state: State) => ({
	isLoading: isLoading(state),
});

export default connect(mapStateToProps, {})(Error);
