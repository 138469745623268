import React from 'react';
import noop from 'lodash/noop';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import DashboardsApp from '@atlassian/jira-dashboards-page/src/view/index.tsx';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import {
	appBundleReadyMark,
	prefetchApiReadyMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { DashboardsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import { PageLayout } from '@atlassian/jira-spa-apps-page-layout/src/page/index.tsx';
import { dashboardsDirectoryPageLoad } from '../utils/performance-analytics/index.tsx';
import type { Props } from './types.tsx';

function DashboardsDirectory({
	Updater,
	tenantContext,
	prefetchResult,
	favoriteChangeContext,
	query,
	onQueryChanged,
}: Props) {
	const { baseUrl, locale, atlassianAccountId, xsrfToken } = tenantContext;
	const { error: prefetchError, loading, data } = prefetchResult;

	if (prefetchError && !loading) {
		// @ts-expect-error - TS2322 - Type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error)' is not assignable to type 'Error | null'.
		return <ErrorFlagRenderer id="directories-dashboards" error={prefetchError} />;
	}

	if (!data) {
		return <DashboardsDirectorySkeleton />;
	}

	return (
		<PageLayout>
			<ReportErrors
				id="unhandled"
				packageName="jiraSpaAppsDirectoriesDashboards"
				teamName="jira-cosmos"
			>
				<Updater />
				<DashboardsApp
					baseUrl={baseUrl}
					accountId={atlassianAccountId}
					locale={locale}
					query={query}
					favoriteChangeContext={favoriteChangeContext}
					prefetchedData={data}
					onQueryChanged={onQueryChanged}
					onUnmount={noop}
					withPageReadyMetrics={false}
					xsrfToken={xsrfToken}
				/>
				{/* @ts-expect-error - TS2769 - No overload matches this call. */}
				<SubmitApdex
					metricKey="jira.directories.dashboards"
					metric={dashboardsDirectoryPageLoad}
					isExpectedToHaveSsr
					withMarks={[appBundleReadyMark, prefetchApiReadyMark]}
				/>
			</ReportErrors>
		</PageLayout>
	);
}
export default AnalyticsSource('dashboards', SCREEN)(DashboardsDirectory);
