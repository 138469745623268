import {
	RETRIEVE,
	RETRIEVED,
	RETRIEVE_FAILED,
	PATCH,
	type Action,
} from '../../actions/dashboards/index.tsx';
import type { DashboardsState } from './types.tsx';

export const initialState: DashboardsState = {
	page: {},
	order: [],
	total: 0,
	isLoading: true,
	isError: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: DashboardsState = initialState, action: Action): DashboardsState => {
	switch (action.type) {
		case RETRIEVE: {
			return {
				...state,
				isLoading: true,
			};
		}

		case RETRIEVED: {
			return {
				...state,
				page: action.payload.page,
				order: action.payload.order,
				total: action.payload.total,
				isLoading: false,
				isError: false,
			};
		}

		case RETRIEVE_FAILED: {
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		}

		case PATCH:
			return {
				...state,
				page: {
					...state.page,
					[action.payload.id]: {
						...state.page[action.payload.id],
						...action.payload.patch,
					},
				},
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
