import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import type { ActionsObservable } from 'redux-observable';
import { pageReady } from '../../services/metrics/index.tsx';
import { READY } from '../../state/actions/app/index.tsx';
import { isWithPageReadyMetrics } from '../../state/selectors/app/index.tsx';
import type { Action, State } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(READY)
		.do(() => {
			isWithPageReadyMetrics(store.getState()) && pageReady();
		})
		.ignoreElements();
