import memoizeOne from 'memoize-one';
import AsyncChangeOwnerDashboardModal from '@atlassian/jira-change-owner-dashboard-modal/src/async.tsx';
import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../state/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OwnProps = OperationModalProps<Record<any, any>>;

const mapStateToPropsFactory = () => {
	const onCancelFactory = memoizeOne((onCancel: OwnProps['onCancel']) => onCancel);
	const onSuccessFactory = memoizeOne((onSuccess: OwnProps['onUpdated']) => onSuccess);
	const onInitiateSuccessFactory = memoizeOne(
		(onDialogShown: OwnProps['onDialogShown']) => onDialogShown,
	);
	const onInitiateFailedFactory = memoizeOne(
		(onDataRetrieveFailed: OwnProps['onDataRetrieveFailed']) => onDataRetrieveFailed,
	);

	return (state: State, ownProps: OwnProps) => ({
		id: ownProps.entityId,
		onCancel: onCancelFactory(ownProps.onCancel),
		onSuccess: onSuccessFactory(ownProps.onUpdated),
		onInitiateSuccess: onInitiateSuccessFactory(ownProps.onDialogShown),
		onInitiateFailed: onInitiateFailedFactory(ownProps.onDataRetrieveFailed),
	});
};

export default connect(
	mapStateToPropsFactory,
	{},
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'Component<{ id: string; onCancel: () => void; onSuccess: (arg1: { patch?: Partial<Record<any, any>> | undefined; } | undefined) => void; onInitiateSuccess: () => void; onInitiateFailed: () => void; } & OperationModalProps<...>>'.
)(AsyncChangeOwnerDashboardModal);
