import { ASC } from '@atlassian/jira-common-constants/src/sort-directions.tsx';
import type { Query } from '../index.tsx';
import { NAME } from './sort-field.tsx';

export const initialQuery: Query = {
	sortField: NAME,
	sortDirection: ASC,
	page: 1,
	filter: {
		text: undefined,
		project: undefined,
		group: undefined,
		owner: undefined,
	},
};
