import type { Query } from '../../../model/index.tsx';
import type { State } from '../../types.tsx';

export const getBaseUrl = (state: State): string => state.app.props.baseUrl;
export const getLoggedInUserAccountId = (state: State): string | null => state.app.props.accountId;
export const getOnQueryChanged = (state: State): ((arg1: Query) => void) =>
	state.app.props.onQueryChanged;
export const getXsrfToken = (state: State): string | null => state.app.props.xsrfToken;
export const getFavoriteChangeContext = (state: State) => state.app.props.favoriteChangeContext;
export const isWithPageReadyMetrics = (state: State) => state.app.props.withPageReadyMetrics;
