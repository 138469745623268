import React, { type MouseEvent } from 'react';
import flow from 'lodash/flow';
import Button from '@atlaskit/button/new';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

type Props = Intl & {
	onClick: (arg1: MouseEvent<HTMLElement>, arg2: AnalyticsEvent) => void;
};

export function Clear(props: Props) {
	const {
		onClick,
		intl: { formatMessage },
	} = props;

	return fg('blu-6146-responsive-directories') ? (
		<Button appearance="subtle" onClick={onClick} shouldFitContainer>
			{formatMessage(messages.label)}
		</Button>
	) : (
		<Button appearance="subtle" onClick={onClick}>
			{formatMessage(messages.label)}
		</Button>
	);
}

export default flow(withFireUiAnalytics({ onClick: 'clearFilters' }), injectIntl)(Clear);
