import React from 'react';
import memoizeOne from 'memoize-one';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import FilterSearch from '@atlassian/jira-common-analytics-v2-wrapped-components/src/filter-search.tsx';
import type { FilterViewProps } from '@atlassian/jira-directory-base/src/model/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import GroupPicker from '@atlassian/jira-group-picker/src/index.tsx';
import ProjectPicker from '@atlassian/jira-project-picker/src/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import UserPicker from '@atlassian/jira-user-picker/src/index.tsx';
import { STANDARD_FILTER_WIDTH } from '@atlassian/jira-common-directory-v2/src/components/filter-bar/constants.tsx';
import { initialQuery } from '../../../model/constants/initial-query.tsx';
import { getBaseUrl, getLoggedInUserAccountId } from '../../../state/selectors/app/index.tsx';
import {
	getFilter,
	getSearchQuery,
	getSelectedProject,
	getSelectedGroup,
	getSelectedOwner,
	isFilterApplied,
} from '../../../state/selectors/query/index.tsx';
import type { State } from '../../../state/types.tsx';
import Clear from './clear/view.tsx';
import Filter from './view.tsx';

const SearchWithAnalytics = withFireUiAnalytics({ onChange: 'search' })(FilterSearch);
const OwnerPickerWithAnalytics = withFireUiAnalytics({ onChange: 'owner' })(UserPicker);
const ProjectTypePickerWithAnalytics = withFireUiAnalytics({ onChange: 'project' })(ProjectPicker);
const GroupPickerWithAnalytics = withFireUiAnalytics({ onChange: 'group' })(GroupPicker);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderFilterSearch = (state: State) => (props: any) => (
	<SearchWithAnalytics
		{...props}
		value={getSearchQuery(state)}
		isAlwaysExpanded
		placeholderAlwaysVisible
		shouldFitContainerWidth={fg('blu-6146-responsive-directories')}
	/>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderOwnerPicker = (state: State) => (props: any) => (
	<OwnerPickerWithAnalytics
		{...props}
		selectedUser={getSelectedOwner(state)}
		suggestedAccountId={getLoggedInUserAccountId(state)}
		baseUrl={getBaseUrl(state)}
		inputId="dashboard-filters-owner-picker-select"
		isCompact
		shouldFitContainer={fg('blu-6146-responsive-directories')}
	/>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderProjectPicker = (state: State) => (props: any) => (
	<ProjectTypePickerWithAnalytics
		{...props}
		selectedProject={getSelectedProject(state)}
		baseUrl={getBaseUrl(state)}
		isCompact
		inputId="dashboard-filters-project-picker-select"
		width={fg('blu-6146-responsive-directories') ? '100%' : `${STANDARD_FILTER_WIDTH}px`}
	/>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderGroupPicker = (state: State) => (props: any) => (
	<GroupPickerWithAnalytics
		{...props}
		selectedGroup={getSelectedGroup(state)}
		baseUrl={getBaseUrl(state)}
		isCompact
		inputId="dashboard-filters-group-picker-select"
		width={fg('blu-6146-responsive-directories') ? '100%' : STANDARD_FILTER_WIDTH}
	/>
);

const renderClear =
	(
		state:
			| State
			| {
					// @ts-expect-error - TS2304 - Cannot find name 'AppState'.
					readonly app: AppState;
					// @ts-expect-error - TS2304 - Cannot find name 'DashboardsState'.
					readonly dashboards: DashboardsState;
					// @ts-expect-error - TS2304 - Cannot find name 'QueryState'.
					readonly query: QueryState;
			  },
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(props: any) =>
		isFilterApplied(state) && <Clear {...props} />;

const mapStateToPropsFactory = () => {
	const renderFilterSearchFactory = memoizeOne(renderFilterSearch);
	const renderOwnerPickerFactory = memoizeOne(renderOwnerPicker);
	const renderProjectPickerFactory = memoizeOne(renderProjectPicker);
	const renderGroupPickerFactory = memoizeOne(renderGroupPicker);
	const renderClearFactory = memoizeOne(renderClear);
	return (state: State, ownProps: FilterViewProps<string>) => ({
		renderFilterSearch: renderFilterSearchFactory(state),
		renderOwnerPicker: renderOwnerPickerFactory(state),
		renderProjectPicker: renderProjectPickerFactory(state),
		renderGroupPicker: renderGroupPickerFactory(state),
		renderClear: renderClearFactory(state),
		initialFilter: initialQuery.filter,
		value: getFilter(state),
		onChange: ownProps.onFilter,
	});
};

export default connect(
	mapStateToPropsFactory,
	{},
	// @ts-expect-error - TS2345 - Argument of type 'ComponentType<Diff<Record<any, any> | (Record<any, any> & { children?: ReactNode; }), InjectIntlVoidProps>>' is not assignable to parameter of type 'Component<{ renderFilterSearch: (props: any) => Element; renderOwnerPicker: (props: any) => Element; renderProjectPicker: (props: any) => Element; renderGroupPicker: (props: any) => Element; renderClear: (props: any) => false | Element; initialFilter: Filter | undefined; value: Filter; onChange: (arg1: string) => vo...'.
)(Filter);
