import isEqual from 'lodash/isEqual';
import type { Filter, Query } from './index.tsx';

const getQueryValues = (target: Query) => {
	const { page, sortField, sortDirection, filter } = target;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { text, owner, project, group } = filter as Filter;
	return {
		page,
		sortField,
		sortDirection,
		filter: {
			text,
			owner: owner && owner.accountId,
			project: project && project.value,
			group: group && group.value,
		},
	};
};

export const isQueryChanged = (newQuery: Query, prevQuery: Query) =>
	!isEqual(getQueryValues(newQuery), getQueryValues(prevQuery));
