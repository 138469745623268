import {
	transformUser,
	transformSharePermission,
} from '@atlassian/jira-common-directory-v2/src/services/transformers.tsx';
import type { Dashboard } from '../model/index.tsx';
import type { Dashboard as RestDashboard } from '../rest/types.tsx';

export const transformDashboard = (baseUrl: string, item: RestDashboard): Dashboard => ({
	id: item.id,
	name: item.name,
	viewUrl: `/jira/dashboards/${item.id}`,
	owner: item.owner ? transformUser(baseUrl, item.owner) : null,
	isFavourite: item.isFavourite,
	popularity: item.popularity || 0,
	shares: item.sharePermissions
		? item.sharePermissions.map(transformSharePermission).filter(Boolean)
		: [],
	edits: item.editPermissions
		? item.editPermissions.map(transformSharePermission).filter(Boolean)
		: [],
	description: item.description,
	isWritable: item.isWritable === true,
	systemDashboard: item.systemDashboard === true,
});
