/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';

export default function DisabledBox({ children }: { children: ReactNode }) {
	return <Box xcss={disabledBoxStyles}>{children}</Box>;
}

const disabledBoxStyles = xcss({
	display: 'contents',
	color: 'color.text.disabled',
	':hover': {
		cursor: 'default',
	},
});
