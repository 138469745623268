import React, { Component, type ComponentType } from 'react';
import { styled as styled2 } from '@compiled/react';
import Avatar, { type AppearanceType } from '@atlaskit/avatar';
import LockIcon from '@atlaskit/icon/glyph/lock';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import WorldIcon from '@atlaskit/icon/glyph/world';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	PUBLIC_SHARE,
	PROJECT_SHARE,
	PROJECT_UNKNOWN_SHARE,
	GROUP_SHARE,
	OPEN_SHARE,
	USER_SHARE,
	type SharePermission,
} from '@atlassian/jira-shared-types/src/share-permission.tsx';
import messages from './messages.tsx';

const assertUnreachable = (_x: never): never => {
	throw new Error("Didn't expect to get here");
};

export type Props = {
	shares: SharePermission[];
} & Intl;

// eslint-disable-next-line jira/react/no-class-components
export class Access extends Component<Props> {
	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static defaultProps = {
		shares: [],
	};

	static renderAvatar(text: string, avatarUrl: string, idx: number, appearance: AppearanceType) {
		return (
			<Container key={idx}>
				<AvatarContainer>
					<Avatar size="small" src={avatarUrl} appearance={appearance} />
				</AvatarContainer>
				<TextContainer>{text}</TextContainer>
			</Container>
		);
	}

	static renderIcon(
		text: string,
		IconComponent: ComponentType<{
			size: 'medium';
			label: '';
		}>,
		idx = 1,
	) {
		return (
			<Container key={idx}>
				<IconContainer>
					<IconComponent label="" size="medium" />
				</IconContainer>
				<TextContainer>{text}</TextContainer>
			</Container>
		);
	}

	render() {
		const {
			shares,
			intl: { formatMessage },
		} = this.props;

		return (
			<CellContainer>
				{shares.length > 0
					? shares.map((share: SharePermission, idx) => {
							switch (share.type) {
								case OPEN_SHARE:
									// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
									return Access.renderIcon(
										formatMessage(messages.myOrganizationAccess),
										// @ts-expect-error - TS2345 - Argument of type 'typeof default' is not assignable to parameter of type 'ComponentType<{ size: "medium"; label: ""; }>'.
										OfficeBuildingIcon,
										idx,
									);
								case PUBLIC_SHARE:
									// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
									return Access.renderIcon(
										formatMessage(messages.publicAccess),
										// @ts-expect-error - TS2345 - Argument of type 'typeof default' is not assignable to parameter of type 'ComponentType<{ size: "medium"; label: ""; }>'.
										WorldIcon,
										idx,
									);
								case GROUP_SHARE:
									// @ts-expect-error - TS2345 - Argument of type 'typeof default' is not assignable to parameter of type 'ComponentType<{ size: "medium"; label: ""; }>'.
									// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
									return Access.renderIcon(share.group.name, PeopleGroupIcon, idx);
								case USER_SHARE:
									return Access.renderAvatar(
										share.user.displayName,
										share.user.avatarUrl,
										idx,
										'circle',
									);
								case PROJECT_SHARE: {
									const roleName = share.role
										? share.role.name
										: formatMessage(messages.allProjectRoles);
									const text = `${share.project.name}, ${roleName}`;

									return Access.renderAvatar(text, share.project.avatarUrl, idx, 'square');
								}
								case PROJECT_UNKNOWN_SHARE:
									return null; // hide from the list
								default:
									assertUnreachable(share);
									return null;
							}
						})
					: // @ts-expect-error - TS2345 - Argument of type 'typeof default' is not assignable to parameter of type 'ComponentType<{ size: "medium"; label: ""; }>'.
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						Access.renderIcon(formatMessage(messages.privateAccess), LockIcon)}
			</CellContainer>
		);
	}
}

export default injectIntl(Access);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled2.div({
	display: 'flex',
	alignItems: 'center',
	paddingBottom: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled2.div({
	paddingRight: token('space.100'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled2.div({
	paddingTop: token('space.025'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.025'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled2.div({
	wordWrap: 'break-word',
	flexGrow: 1,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CellContainer = styled2.div({
	paddingTop: token('space.075'),
	display: 'flex',
	flexDirection: 'column',
});
