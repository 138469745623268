import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import type { Dashboard } from '@atlassian/jira-dashboards-common/src/model/index.tsx';
import type { Dashboard as RestDashboard } from '@atlassian/jira-dashboards-common/src/rest/types.tsx';
import { transformDashboard } from '@atlassian/jira-dashboards-common/src/services/transformers.tsx';
import type { Response } from '@atlassian/jira-dashboards-common/src/services/types.tsx';

export const transformDashboards = (
	baseUrl: string,
	dashboards: RestDashboard[],
	total: number,
): Response => {
	const order = dashboards.map((i) => i.id);
	const responsesById: {
		[key: string]: RestDashboard;
	} = keyBy(dashboards, (dashboard) => dashboard.id);
	const page: {
		[key: string]: Dashboard;
	} = mapValues(responsesById, (response: RestDashboard) => transformDashboard(baseUrl, response));

	return {
		page,
		order,
		total,
	};
};
