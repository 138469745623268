import PopularityCell from '@atlassian/jira-common-directory-v2/src/components/popularity-cell/view.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { EntityCellProps } from '../../../../model/index.tsx';
import { getPopularity } from '../../../../state/selectors/dashboards/index.tsx';
import type { State } from '../../../../state/types.tsx';

const mapStateToProps = () => (state: State, ownProps: EntityCellProps) => ({
	popularity: getPopularity(state, ownProps.id),
});

export default connect(mapStateToProps, {})(PopularityCell);
