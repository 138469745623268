import AccessCell from '@atlassian/jira-common-directory-v2/src/components/access-cell/view.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { SharePermission } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import type { EntityCellProps } from '../../../../model/index.tsx';
import { getEdits } from '../../../../state/selectors/dashboards/index.tsx';
import type { State } from '../../../../state/types.tsx';

type StateProps = {
	shares: SharePermission[];
};

const mapStateToProps = (state: State, ownProps: EntityCellProps): StateProps => ({
	shares: getEdits(state, ownProps.id),
});

export default connect(
	mapStateToProps,
	// We attempted to fix the below FixFlowMe but ran into issue with cross package dependencies which we do not want
	// to introduce. We believe the unused fields in Props causes the below issue.
	{},
)(AccessCell);
