/** @jsx jsx */
import type { ReactNode } from 'react';
import { jsx, cssMap } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Box, Flex, xcss, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import DisabledBox from '../disabled-box/index.tsx';

type Props = {
	avatarUrl: string | undefined;
	displayName: string;
	href: string | undefined;
	appearance: 'square' | 'circle';
	isDeleting?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	onClick: () => void | null | undefined;
};

const styles = cssMap({
	avatarContainer: {
		paddingRight: token('space.100'),
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		lineHeight: 0,
		flex: '0 0 auto',
	},
	container: {
		paddingTop: token('space.075'),
		paddingBottom: token('space.075'),
		display: 'flex',
		flex: '1 1 auto',
	},
	contentContainer: {
		display: 'flex',
		alignItems: 'center',
		flex: '1 1 auto',
		width: '100%',
		overflow: 'hidden',
	},
	contentContainerOld: {
		display: 'flex',
		alignItems: 'center',
		flex: '1 1 auto',
		width: '100%',
	},
	accessibleContentContainer: {
		paddingTop: token('space.100'),
		paddingBottom: token('space.100'),
		display: 'flex',
		alignItems: 'center',
		flex: '1 1 auto',
		width: '100%',
	},
	link: {
		width: '100%',
	},
	textContainer: {
		wordWrap: 'break-word',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
});

export default function AvatarTitleCell(props: Props) {
	const { avatarUrl, displayName, href, appearance, isDeleting, onClick } = props;

	const content =
		!href && fg('blu-6080-directory-table-tooltips') ? (
			<Flex alignItems="start" xcss={contentWrapperStyles}>
				<div css={styles.avatarContainer}>
					<Avatar src={avatarUrl} size="small" appearance={appearance} />
				</div>
				<Inline xcss={inlineTextStyles}>
					{isDeleting && fg('hix-6302_project_delete_improvements') ? (
						<DisabledBox>{displayName}</DisabledBox>
					) : (
						displayName
					)}
				</Inline>
			</Flex>
		) : (
			<Content>
				<div css={styles.avatarContainer}>
					<Avatar src={avatarUrl} size="small" appearance={appearance} />
				</div>
				<div css={styles.textContainer}>
					{isDeleting && fg('hix-6302_project_delete_improvements') ? (
						<DisabledBox>{displayName} </DisabledBox>
					) : (
						displayName
					)}
				</div>
			</Content>
		);

	if (href) {
		return (
			<div css={styles.container}>
				{isDeleting && fg('hix-6302_project_delete_improvements') ? (
					content
				) : (
					<div css={styles.link}>
						{fg('blu-6080-directory-table-tooltips') ? (
							<Box xcss={contentWrapperStyles}>
								<Tooltip
									content={displayName}
									isScreenReaderAnnouncementDisabled
									delay={1000}
									position="bottom-start"
								>
									<Link href={href} onClick={onClick}>
										{content}
									</Link>
								</Tooltip>
							</Box>
						) : (
							<Link href={href} onClick={onClick}>
								{content}
							</Link>
						)}
					</div>
				)}
			</div>
		);
	}
	return (
		<div
			css={[
				isVisualRefreshEnabled() && fg('visual-refresh_drop_4')
					? styles.contentContainer
					: styles.contentContainerOld,
			]}
		>
			{content}
		</div>
	);
}

AvatarTitleCell.defaultProps = {
	avatarUrl: undefined,
	href: undefined,
	appearance: 'circle',
	onClick: undefined,
};

const Content = (props: { children: ReactNode }) => {
	return isVisualRefreshEnabled() ? (
		<Box xcss={accessibleContentContainerStyles}>{props.children}</Box>
	) : (
		<div css={styles.accessibleContentContainer}>{props.children}</div>
	);
};

const contentWrapperStyles = xcss({ maxWidth: '100%', paddingBlock: 'space.100' });

const inlineTextStyles = xcss({ paddingBlockStart: 'space.025' });

const accessibleContentContainerStyles = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	width: '100%',
	height: '40px',
});
