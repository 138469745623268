import { createSelector } from 'reselect';
import AsyncCopyDashboardModal from '@atlassian/jira-copy-dashboard-modal/src/async.tsx';
import type { Props } from '@atlassian/jira-copy-dashboard-modal/src/index.tsx';
import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { withRouter, type RouterActionPush } from '@atlassian/react-resource-router';
import { getBaseUrl } from '../../../state/selectors/app/index.tsx';
import type { State } from '../../../state/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OwnProps = OperationModalProps<Record<any, any>> & {
	push: RouterActionPush;
};

const selectBaseUrl = (state: State) => getBaseUrl(state);
const selectOwnProps = (_: State, ownProps: OwnProps) => ownProps;

const navigateToDashboard = (baseUrl: string, id: string, push: RouterActionPush) => {
	push(`/jira/dashboards/${id}`);
};

const makeMapStateToProps = () =>
	createSelector(
		[selectBaseUrl, selectOwnProps],
		(baseUrl, ownProps): Props => ({
			id: ownProps.entityId,
			onCancel: ownProps.onCancel,
			onSuccess: (_, { id }) => {
				navigateToDashboard(baseUrl, id, ownProps.push);
				// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
				ownProps.onUpdated();
			},
			onInitiateSuccess: ownProps.onDialogShown,
			onInitiateFailed: ownProps.onDataRetrieveFailed,
		}),
	);

const ConnectedModal = connect(
	makeMapStateToProps,
	null,
	(stateProps: Props): Props => stateProps,
)(AsyncCopyDashboardModal);

export default withRouter(ConnectedModal);
