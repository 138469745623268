import type { Query } from '../../../model/index.tsx';

export const SET_QUERY = 'state.actions.query.SET_QUERY' as const;

export type SetQueryPayload = Query;

export type SetQueryAction = {
	type: typeof SET_QUERY;
	payload: SetQueryPayload;
};

export const setQuery = (payload: SetQueryPayload): SetQueryAction => ({
	type: SET_QUERY,
	payload,
});

export const PATCH_QUERY = 'state.actions.query.PATCH_QUERY' as const;
export type PatchQueryPayload = Partial<Query>;
export type PatchQueryAction = {
	type: typeof PATCH_QUERY;
	payload: PatchQueryPayload;
};
export const patchQuery = (payload: PatchQueryPayload): PatchQueryAction => ({
	type: PATCH_QUERY,
	payload,
});

export type Action = SetQueryAction | PatchQueryAction;
