import React, { type ComponentType } from 'react';
import '@atlaskit/css-reset';
import PageAppInitialisationAnalytics from '@atlassian/jira-common-analytics-page-app-initialisation/src/index.tsx';
import QueryParamAdapter from '@atlassian/jira-dashboards-query-param-adapter/src/index.tsx';
import { FavoriteChangeConsumer } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import { withSuspense } from '@atlassian/jira-platform-resources-utils/src/utils/with-suspense/index.tsx';
import { dashboardsDirectoryResource } from '@atlassian/jira-router-resources-directories-dashboards/src/services/dashboards-directory/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { withRouter, type RouteContext, useResource } from '@atlassian/react-resource-router';
import Dashboards from './view/index.tsx';

export type Props = RouteContext;

const useDashboardsDirectoryResource = withSuspense(() => useResource(dashboardsDirectoryResource));

export const DashboardsDirectory = ({ location }: Props) => {
	const state = useDashboardsDirectoryResource();
	const tenantContext = useTenantContext();

	return (
		<QueryParamAdapter location={location}>
			{({ query, onQueryChanged }) => (
				<FavoriteChangeConsumer>
					{(favoriteChangeContext) => (
						<UFOSegment name="dashboards-directory">
							<Dashboards
								tenantContext={tenantContext}
								prefetchResult={{ ...state }}
								favoriteChangeContext={favoriteChangeContext}
								query={query}
								onQueryChanged={onQueryChanged}
							/>
						</UFOSegment>
					)}
				</FavoriteChangeConsumer>
			)}
		</QueryParamAdapter>
	);
};

// @ts-expect-error - TS2322 - Type '{ (props: RouteContext & { history: BrowserHistory; }): Element; displayName: string; }' is not assignable to type 'ComponentType<Record<any, any>>'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RouterWrapper: ComponentType<Record<any, any>> = withRouter(DashboardsDirectory);
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (props: Record<any, any>) => (
	<>
		<MarkProductStart />
		<PageAppInitialisationAnalytics
			appNameSuffixForV2Event="directory.dashboards"
			appNameForV3OperationalEvent="dashboardsDirectory"
		/>
		<RouterWrapper {...props} />
	</>
);
