import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import noResultsIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/assets/search-no-results.svg';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import imageUrlOld from '../../../../assets/generic-no-results.svg';

/*
 * The react-redux `connect` function is passing in a `this.props.dispatch` function but this component does not use it.
 * It is not a problem but it must be either defined in this Props type, or the type must be inexact.
 */
type Props = {
	message: MessageDescriptor;
	intl: IntlShape;
};

function DefaultEmptyView(props: Props) {
	const { message } = props;
	const {
		intl: { formatMessage },
	} = props;
	return (
		<div data-test-id="global-pages.directories.directory-base.content.table.default-empty-view.empty-state">
			<EmptyState
				imageUrl={
					isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
						? noResultsIllustration
						: imageUrlOld
				}
				header={formatMessage(message)}
				size="narrow"
				headingLevel={2}
			/>
		</div>
	);
}

export default injectIntl(DefaultEmptyView);
