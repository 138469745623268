import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import type { User } from '@atlassian/jira-common-directory-v2/src/model/index.tsx';
import {
	CHANGE_OWNER,
	COPY,
	EDIT,
	DELETE,
} from '@atlassian/jira-directory-base/src/model/operation-types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { EntityCellProps } from '../../../../model/index.tsx';
import { getLoggedInUserAccountId } from '../../../../state/selectors/app/index.tsx';
import {
	getOwner,
	getIsWritable,
	getSystemDashboard,
	getName,
} from '../../../../state/selectors/dashboards/index.tsx';
import type { State } from '../../../../state/types.tsx';
import Actions from './view.tsx';

type StateProps = {
	isWritable: boolean;
	systemDashboard: boolean;
	owner: User | null;
	loggedInUserAccountId: string | null;
	showSpinner: boolean;
	caption: string;
	onCopy: () => void;
	onChangeOwner: () => void;
	onEdit: () => void;
	onDelete: () => void;
};

const mapStateToPropsFactory = () => {
	const onCopyFactory = memoizeOne(
		(onOperationRequested: EntityCellProps['onOperationRequested']) => () =>
			// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
			onOperationRequested(COPY),
	);
	const onEditFactory = memoizeOne(
		(onOperationRequested: EntityCellProps['onOperationRequested']) => () =>
			// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
			onOperationRequested(EDIT),
	);
	const onChangeOwnerFactory = memoizeOne(
		(onOperationRequested: EntityCellProps['onOperationRequested']) => () =>
			// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
			onOperationRequested(CHANGE_OWNER),
	);
	const onDeleteFactory = memoizeOne(
		(onOperationRequested: EntityCellProps['onOperationRequested']) => () =>
			// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
			onOperationRequested(DELETE),
	);
	return (state: State, ownProps: EntityCellProps): StateProps => ({
		owner: getOwner(state, ownProps.id),
		caption: getName(state, ownProps.id),
		loggedInUserAccountId: getLoggedInUserAccountId(state),
		isWritable: getIsWritable(state, ownProps.id),
		systemDashboard: getSystemDashboard(state, ownProps.id),
		showSpinner: ownProps.isOperationInProgress,
		onCopy: onCopyFactory(ownProps.onOperationRequested),
		onEdit: onEditFactory(ownProps.onOperationRequested),
		onChangeOwner: onChangeOwnerFactory(ownProps.onOperationRequested),
		onDelete: onDeleteFactory(ownProps.onOperationRequested),
	});
};

export default flow(
	withFireUiAnalytics({
		onCopyDashboardClick: 'copy',
		onEditDashboardClick: 'edit',
		onDeleteDashboardClick: 'delete',
		onChangeOwnerClick: 'changeOwner',
	}),
	connect(mapStateToPropsFactory, {}),
)(Actions);
