import flow from 'lodash/flow';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { EntityCellProps } from '../../../../model/index.tsx';
import { getName, getViewUrl } from '../../../../state/selectors/dashboards/index.tsx';
import type { State } from '../../../../state/types.tsx';
import Title from './view.tsx';

const mapStateToProps = () => (state: State, ownProps: EntityCellProps) => ({
	caption: getName(state, ownProps.id),
	href: getViewUrl(state, ownProps.id),
});

export default flow(
	withFireUiAnalytics({
		onClick: 'dashboard',
	}),
	connect(mapStateToProps, {}),
)(Title);
