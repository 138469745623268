import pick from 'lodash/pick';
import { stringify } from 'query-string';
import type { Filter } from '@atlassian/jira-common-directory-v2/src/model/shareable-entities/index.tsx';
import { getErrorFromResponse } from '@atlassian/jira-common-directory-v2/src/rest/errors.tsx';
import { fetchWithRetry } from '@atlassian/jira-common-directory-v2/src/rest/index.tsx';
import { logSafeErrorWithoutCustomerData } from '@atlassian/jira-common-directory-v2/src/utils/log-safe-error/index.tsx';
import { DASHBOARDS_ERROR_ANALYTICS } from '@atlassian/jira-dashboards-common/src/common/constant.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import type { PageRequest } from '@atlassian/jira-shared-types/src/rest/jira/page.tsx';
import type { Response as ApiResponse } from './types.tsx';

export const createEndpointPath = () => '/rest/api/2/dashboard/search';

const createQueryFilter = (
	filter: Filter,
): {
	dashboardName: string | undefined;
} => ({
	dashboardName: filter.text,
});

const createProjectFilter = (
	filter: Filter,
): {
	projectId: string | undefined;
} => ({
	projectId: filter.project ? filter.project.value : undefined,
});

const createGroupFilter = (
	filter: Filter,
): {
	groupname: string | undefined;
} => ({
	groupname: filter.group ? filter.group.value : undefined,
});

const createOwnerFilter = (
	filter: Filter,
): {
	accountId: string | undefined;
} => ({
	accountId: filter.owner ? filter.owner.accountId : undefined,
});

const logError = async (error: Error, currentTry: number) => {
	logSafeErrorWithoutCustomerData(
		error,
		'ops.rest.dashboards.get',
		DASHBOARDS_ERROR_ANALYTICS,
		`Cannot fetch dashboards - is SSR: ${String(__SERVER__)}, attempt: ${currentTry}`,
	);
};

export const createUrl = (baseUrl: string, request: PageRequest, filter: Filter) => {
	const queryParams = {
		...pick(request, 'startAt', 'maxResults', 'orderBy', 'expand'),
		...(filter.text ? createQueryFilter(filter) : undefined),
		...(filter.project ? createProjectFilter(filter) : undefined),
		...(filter.group ? createGroupFilter(filter) : undefined),
		...(filter.owner ? createOwnerFilter(filter) : undefined),
	};
	return `${createEndpointPath()}?${stringify(queryParams)}`;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	baseUrl: string,
	request: PageRequest,
	filter: Filter,
): Promise<ApiResponse> => {
	const url = createUrl(baseUrl, request, filter);
	const response = await fetchWithRetry(url, getDefaultOptions(url), logError);

	if (response.ok) {
		return response.json();
	}

	throw await getErrorFromResponse(response, 'Cannot fetch dashboards');
};
