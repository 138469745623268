import type { ExpandList } from '@atlassian/jira-shared-types/src/rest/jira/page.tsx';

export const EXPAND_PARAMETERS: ExpandList = [
	'owner',
	'favourite',
	'favouritedCount',
	'sharePermissions',
	'editPermissions',
	'description',
	'isWritable',
	'systemDashboard',
];
