import React, { type ComponentType } from 'react';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import type { CreateButtonProps } from '@atlassian/jira-create-dashboards-button/src/index.tsx';
import type { DashboardsProps as DirectoryProps } from '@atlassian/jira-dashboards-main/src/index.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

type Props = {
	CreateButton: ComponentType<CreateButtonProps>;
	DashboardsDirectory: ComponentType<DirectoryProps>;
} & DirectoryProps & {
		intl: IntlShape;
	};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const {
		CreateButton,
		DashboardsDirectory,
		intl: { formatMessage },
		...directoryProps
	} = props;

	const actions = <CreateButton baseUrl={toBaseUrl(props.baseUrl)} />;
	return (
		<>
			<DocumentTitle title={formatMessage(messages.pageTitle)} />
			<PageHeader truncateTitle actions={actions}>
				{formatMessage(messages.pageTitle)}
			</PageHeader>
			<DashboardsDirectory {...directoryProps} />
		</>
	);
};
