import omit from 'lodash/omit';
import { SET_APP_PROPS, type SetAppPropsAction } from '../../actions/app/index.tsx';
import type { AppState } from './types.tsx';

type Action = SetAppPropsAction;

export const initialState: AppState = {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	props: undefined as any, // set immediately
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AppState = initialState, action: Action): AppState => {
	switch (action.type) {
		case SET_APP_PROPS: {
			return {
				...state,
				props: omit(action.payload, 'query'),
			};
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
