import type { Actions } from './types.tsx';

const actions: Actions = {
	open:
		() =>
		({ setState }) => {
			setState({ isOpen: true });
		},

	close:
		() =>
		({ setState }) => {
			setState({ isOpen: false });
		},
};

export default actions;
