import AsyncDeleteDashboardModal from '@atlassian/jira-delete-dashboard-modal/src/async.tsx';
import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../state/types.tsx';

export default connect(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(state: State, ownProps: OperationModalProps<Record<any, any>>) => ({
		id: ownProps.entityId,
		onCancel: ownProps.onCancel,
		onSuccess: ownProps.onUpdated,
		onInitiateSuccess: ownProps.onDialogShown,
		onInitiateFailed: ownProps.onDataRetrieveFailed,
	}),
	{},
	// @ts-expect-error - TS2345 - Argument of type '(props: JSX.LibraryManagedAttributes<typeof DeleteDashboardModalType, ComponentProps<typeof DeleteDashboardModalType>>) => JSX.Element' is not assignable to parameter of type 'Component<{ id: string; onCancel: () => void; onSuccess: (arg1: { patch?: Partial<Record<any, any>> | undefined; } | undefined) => void; onInitiateSuccess: () => void; onInitiateFailed: () => void; } & OperationModalProps<...>>'.
)(AsyncDeleteDashboardModal);
