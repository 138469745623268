// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type CopyDashboardModalType from './index.tsx';

const AsyncCopyDashboardModal = lazyForPaint(
	() => import(/* webpackChunkName: "async-copy-dashboard-modal" */ './index'),
);

const AsyncCopyDashboardModalContainer = (
	props: JSX.LibraryManagedAttributes<
		typeof CopyDashboardModalType,
		ComponentProps<typeof CopyDashboardModalType>
	>,
) => (
	<Placeholder name="dashboard-modal" fallback={<></>}>
		<AsyncCopyDashboardModal {...props} />
	</Placeholder>
);
export default AsyncCopyDashboardModalContainer;
