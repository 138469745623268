import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	previousButtonOld: {
		id: 'directory-base.content.paginator.previous-button-old',
		defaultMessage: 'Prev',
		description: 'Previous page button of pagination in a directory level page',
	},
	nextButtonOld: {
		id: 'directory-base.content.paginator.next-button-old',
		defaultMessage: 'Next',
		description: 'Next page button of pagination in a directory level page',
	},
	previousButton: {
		id: 'directory-base.content.paginator.previous-button',
		defaultMessage: 'Previous page',
		description: 'Previous page button of pagination in a directory level page',
	},
	nextButton: {
		id: 'directory-base.content.paginator.next-button',
		defaultMessage: 'Next page',
		description: 'Next page button of pagination in a directory level page',
	},
});
