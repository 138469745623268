import { combineReducers } from 'redux';
import app from './app/index.tsx';
import dashboards from './dashboards/index.tsx';
import query from './query/index.tsx';

export default combineReducers({
	app,
	dashboards,
	query,
});
