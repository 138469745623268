import React, { type ReactElement } from 'react';
import QueryParamAdapter from '@atlassian/jira-common-directory-v2/src/query-param-adapter/index.tsx';
import { initialQuery } from '@atlassian/jira-dashboards-main/src/model/constants/initial-query.tsx';
import type { Query } from '@atlassian/jira-dashboards-main/src/model/index.tsx';
import type { Location } from '@atlassian/react-resource-router';
import { filterFromParams, filterToParams } from './transformers/filter.tsx';
import { sortFieldFromParam, sortFieldToParam } from './transformers/sort-field.tsx';

type Props = {
	location: Location;
	children: (arg1: { query: Query; onQueryChanged: (arg1: Query) => void }) => ReactElement;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ location, children }: Props) => (
	<QueryParamAdapter
		location={location}
		sortFieldToParam={sortFieldToParam}
		sortFieldFromParam={sortFieldFromParam}
		filterFromParams={filterFromParams}
		filterToParams={filterToParams}
		defaultQuery={initialQuery}
	>
		{children}
	</QueryParamAdapter>
);
