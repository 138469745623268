import React from 'react';
import UserCellDumb from '@atlassian/jira-common-directory-v2/src/components/user-cell/index.tsx';
import type { Props as UserCellDumbProps } from '@atlassian/jira-common-directory-v2/src/components/user-cell/view.tsx';
import type { User } from '@atlassian/jira-common-directory-v2/src/model/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { EntityCellProps } from '../../../../model/index.tsx';
import { getOwner } from '../../../../state/selectors/dashboards/index.tsx';
import type { State } from '../../../../state/types.tsx';

type StateProps = {
	user: User | null;
};

type OwnProps = EntityCellProps;

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	user: getOwner(state, ownProps.id),
});

type EntityCellWrapperType<T> = EntityCellProps & T;
type UserCellProps = EntityCellWrapperType<UserCellDumbProps>;

const UserCell = (props: UserCellProps) => {
	const { user } = props;
	return <UserCellDumb user={user} />;
};

UserCell.defaultProps = {
	onClick: null,
};

// @ts-expect-error - TS2345 - Argument of type '{ (props: UserCellProps): JSX.Element; defaultProps: { onClick: null; }; }' is not assignable to parameter of type 'Component<EntityCellProps & Props>'.
export default connect(mapStateToProps, {})(UserCell);
