import type {
	Filter,
	Project,
	Group,
	Owner,
} from '@atlassian/jira-common-directory-v2/src/model/shareable-entities/index.tsx';
import type {
	FilterToParamTransformer,
	ParamToFilterTransformer,
} from '@atlassian/jira-common-directory-v2/src/query-param-adapter/model/index.tsx';

export const filterToParams: FilterToParamTransformer<Filter> = ({
	group,
	owner,
	project,
	text,
}: Filter = {}) => ({
	name: text || undefined,
	owner: (owner && owner.accountId) || undefined,
	selectedProject: (project && project.value) || 'anything',
	selectedGroup: (group && group.value) || 'anything',
});

const transformSelectedOwner = (input?: string): Owner | undefined =>
	input ? { accountId: input } : undefined;

const transformSelectedProject = (input?: string): Project | undefined =>
	input && input !== 'anything'
		? {
				value: input,
			}
		: undefined;

const transformSelectedGroup = (input?: string): Group | undefined =>
	input && input !== 'anything'
		? {
				value: input,
				name: input,
			}
		: undefined;

export const filterFromParams: ParamToFilterTransformer<Filter> = (params) => ({
	text: params.name || params.searchName || undefined,
	owner: transformSelectedOwner(params.owner),
	project: transformSelectedProject(params.selectedProject),
	group: transformSelectedGroup(params.selectedGroup),
});
