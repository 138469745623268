import type {
	ParamToSortFieldTransformer,
	SortFieldToParamTransformer,
} from '@atlassian/jira-common-directory-v2/src/query-param-adapter-legacy/model/index.tsx';
import {
	FAVOURITED,
	POPULARITY,
	NAME,
	OWNER,
	type SortField,
} from '@atlassian/jira-dashboards-main/src/model/constants/sort-field.tsx';

const sortFieldMap: {
	[key: string]: SortField;
} = {
	[NAME.toLowerCase()]: NAME,
	[OWNER.toLowerCase()]: OWNER,
	[POPULARITY.toLowerCase()]: POPULARITY,
	[FAVOURITED.toLowerCase()]: FAVOURITED,
};

export const sortFieldToParam: SortFieldToParamTransformer<SortField> = (field) =>
	field.toLowerCase();

export const sortFieldFromParam: ParamToSortFieldTransformer<SortField> = (param) =>
	sortFieldMap[param] ? sortFieldMap[param] : null;
