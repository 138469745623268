import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { FavoriteCellView } from '@atlassian/jira-common-directory-v2/src/components/favourite-cell-v2/view.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { EntityCellProps } from '../../../../model/index.tsx';
import {
	getName,
	isFavorite,
	isPending,
	getChangeFavorite,
} from '../../../../state/selectors/dashboards/index.tsx';
import type { State } from '../../../../state/types.tsx';

const mapStateToPropsFactory = () => {
	const onClickFactory = memoizeOne(
		(state: State, id: string) => (analyticsEvent: UIAnalyticsEvent) => {
			const changeFavorite = getChangeFavorite(state);
			return changeFavorite(
				{
					id,
					type: 'dashboards',
					value: !isFavorite(state, id),
				},
				analyticsEvent,
			);
		},
	);
	return (state: State, ownProps: EntityCellProps) => ({
		isFavorite: isFavorite(state, ownProps.id),
		favoriteItemName: getName(state, ownProps.id),
		pending: isPending(state, ownProps.id),
		onClick: onClickFactory(state, ownProps.id),
		isShownInList: true,
	});
};

export default flow(
	AnalyticsSubject('dashboard'),
	// @ts-expect-error - TS2589 - Type instantiation is excessively deep and possibly infinite.
	connect(mapStateToPropsFactory, {}),
)(FavoriteCellView);
