import React, { Component, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import { FilterBar } from '@atlassian/jira-common-directory-v2/src/components/filter-bar/FilterBar.tsx';
import { FilterItem } from '@atlassian/jira-common-directory-v2/src/components/filter-bar/FilterItem.tsx';
import type {
	Project,
	Group,
	Owner,
	Filter as FiltersType,
} from '@atlassian/jira-common-directory-v2/src/model/shareable-entities/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type OnSearchChange = (arg1: string, arg2: AnalyticsEvent) => void;
type OnOwnerChange = (owner: Owner, event: AnalyticsEvent) => void;
type OnProjectChange = (project: Project, event: AnalyticsEvent) => void;
type OnGroupChange = (group: Group, event: AnalyticsEvent) => void;
type OnClear = (event: AnalyticsEvent) => void;

export type Props = {
	renderFilterSearch: (arg1: { onChange: OnSearchChange; placeholder: string }) => ReactElement;
	renderOwnerPicker: (arg1: {
		onChange: OnOwnerChange;
		placeholder: string;
		label?: string;
	}) => ReactElement;
	renderProjectPicker: (arg1: {
		onChange: OnProjectChange;
		placeholder: string;
		label?: string;
	}) => ReactElement;
	renderGroupPicker: (arg1: {
		onChange: OnGroupChange;
		placeholder: string;
		label?: string;
	}) => ReactElement;
	renderClear: (arg1: { onClick: OnClear }) => ReactElement;
	initialFilter: FiltersType;
	value: FiltersType;
} & Intl & {
		onChange: (filters: FiltersType, event: AnalyticsEvent) => void;
	};

// eslint-disable-next-line jira/react/no-class-components
export class Filter extends Component<Props> {
	// @ts-expect-error - TS2322 - Type 'undefined' is not assignable to type 'string'.
	onSearchChange: OnSearchChange = (text = undefined, analyticsEvent) => {
		this.props.onChange({ ...this.props.value, text }, analyticsEvent);
	};

	onOwnerChange: OnOwnerChange = (owner, analyticsEvent) => {
		this.props.onChange({ ...this.props.value, owner }, analyticsEvent);
	};

	onProjectChange: OnProjectChange = (project, analyticsEvent) => {
		this.props.onChange({ ...this.props.value, project }, analyticsEvent);
	};

	onGroupChange: OnGroupChange = (group, analyticsEvent) => {
		this.props.onChange({ ...this.props.value, group }, analyticsEvent);
	};

	onClear: OnClear = (analyticsEvent) => {
		this.props.onChange({ ...this.props.initialFilter }, analyticsEvent);
	};

	render() {
		const {
			renderFilterSearch,
			renderOwnerPicker,
			renderProjectPicker,
			renderGroupPicker,
			renderClear,
			intl: { formatMessage },
		} = this.props;

		const isVisualRefresh = isVisualRefreshEnabled();

		return fg('blu-6146-responsive-directories') ? (
			<FilterBar>
				<FilterItem expandOnSmall>
					{renderFilterSearch({
						onChange: this.onSearchChange,
						placeholder: formatMessage(messages.searchPlaceholder),
					})}
				</FilterItem>
				<FilterItem>
					<Box xcss={!isVisualRefresh && itemInnerStyles}>
						{renderOwnerPicker({
							onChange: this.onOwnerChange,
							placeholder: isVisualRefresh
								? formatMessage(messages.ownerLabel)
								: formatMessage(messages.ownerPlaceholder),
							label: isVisualRefresh ? undefined : formatMessage(messages.ownerLabel),
						})}
					</Box>
				</FilterItem>
				<FilterItem>
					<Box xcss={!isVisualRefresh && itemInnerStyles}>
						{renderProjectPicker({
							onChange: this.onProjectChange,
							placeholder: isVisualRefresh
								? formatMessage(messages.projectLabel)
								: formatMessage(messages.projectPlaceholder),
							label: isVisualRefresh ? undefined : formatMessage(messages.projectLabel),
						})}
					</Box>
				</FilterItem>
				<FilterItem>
					<Box xcss={!isVisualRefresh && itemInnerStyles}>
						{renderGroupPicker({
							onChange: this.onGroupChange,
							placeholder: isVisualRefresh
								? formatMessage(messages.groupLabel)
								: formatMessage(messages.groupPlaceholder),
							label: isVisualRefresh ? undefined : formatMessage(messages.groupLabel),
						})}
					</Box>
				</FilterItem>
				<FilterItem expandOnSmall>{renderClear({ onClick: this.onClear })}</FilterItem>
			</FilterBar>
		) : (
			<FilterContainer>
				{renderFilterSearch({
					onChange: this.onSearchChange,
					placeholder: formatMessage(messages.searchPlaceholder),
				})}
				<LabelWrapper>
					{renderOwnerPicker({
						onChange: this.onOwnerChange,
						placeholder: isVisualRefresh
							? formatMessage(messages.ownerLabel)
							: formatMessage(messages.ownerPlaceholder),
						label: isVisualRefresh ? undefined : formatMessage(messages.ownerLabel),
					})}
				</LabelWrapper>
				<LabelWrapper>
					{renderProjectPicker({
						onChange: this.onProjectChange,
						placeholder: isVisualRefresh
							? formatMessage(messages.projectLabel)
							: formatMessage(messages.projectPlaceholder),
						label: isVisualRefresh ? undefined : formatMessage(messages.projectLabel),
					})}
				</LabelWrapper>
				<LabelWrapper>
					{renderGroupPicker({
						onChange: this.onGroupChange,
						placeholder: isVisualRefresh
							? formatMessage(messages.groupLabel)
							: formatMessage(messages.groupPlaceholder),
						label: isVisualRefresh ? undefined : formatMessage(messages.groupLabel),
					})}
				</LabelWrapper>
				{renderClear({ onClick: this.onClear })}
			</FilterContainer>
		);
	}
}

export default injectIntl(Filter);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterContainer = styled.div({
	display: 'flex',
	alignItems: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > :not(:last-child)': {
		marginRight: token('space.200'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelWrapper = styled.div({
	width: '200px',
	marginTop: token('space.negative.250'),
});

const itemInnerStyles = xcss({
	marginTop: 'space.negative.250',
});
