import React from 'react';
import StarUnstarredIcon from '@atlaskit/icon/core/star-unstarred';
import StarFilledIcon from '@atlaskit/icon/core/migration/star-starred--star-filled';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { messages } from './messages.tsx';

const StarTitleCell = () => {
	const { formatMessage } = useIntl();

	if (isVisualRefreshEnabled() && !fg('visual-refresh_drop_6')) {
		return (
			<StarUnstarredIcon
				LEGACY_fallbackIcon={StarFilledIcon}
				LEGACY_size="small"
				label={formatMessage(messages.starred)}
			/>
		);
	}

	return <StarFilledIcon LEGACY_size="small" label={formatMessage(messages.starred)} />;
};

export default StarTitleCell;
