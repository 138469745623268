import React from 'react';
import LinkCell from '@atlassian/jira-common-directory-v2/src/components/link-cell/view.tsx';
import { TransitionComponent } from '@atlassian/jira-common-directory-v2/src/components/transition-component/index.tsx';

export type Props = {
	caption: string;
	href: string;
	onClick: () => void;
};

export default function Title({ onClick, href, caption }: Props) {
	return (
		<TransitionComponent href={href} onClick={onClick}>
			{(props) => <LinkCell {...props} caption={caption} />}
		</TransitionComponent>
	);
}
