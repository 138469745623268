import { createSelector } from 'reselect';
import StarTitle from '@atlassian/jira-common-directory-v2/src/components/star-title-cell/view.tsx';
import {
	CHANGE_OWNER,
	COPY,
	EDIT,
	DELETE,
} from '@atlassian/jira-directory-base/src/model/operation-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { NAME, POPULARITY, FAVOURITED, OWNER } from '../model/constants/sort-field.tsx';
import { getLoggedInUserAccountId } from '../state/selectors/app/index.tsx';
import AccessCell from './content/cells/access/index.tsx';
import ActionsCell from './content/cells/actions/index.tsx';
import EditCell from './content/cells/edit/index.tsx';
import FavoriteCell from './content/cells/favourite/index.tsx';
import OwnerCell from './content/cells/owner/index.tsx';
import PopularityCell from './content/cells/popularity/index.tsx';
import TitleCell from './content/cells/title/index.tsx';
import ChangeOwnerModal from './content/change-owner-modal/index.tsx';
import CopyModal from './content/copy-modal/index.tsx';
import DeleteModal from './content/delete-modal/index.tsx';
import EditModal from './content/edit-modal/index.tsx';
import messages from './messages.tsx';

export const operationModals = {
	[COPY]: CopyModal,
	[EDIT]: EditModal,
	[DELETE]: DeleteModal,
	[CHANGE_OWNER]: ChangeOwnerModal,
} as const;

export const emptyFilterResultConfiguration = {
	message: messages.emptyFilterResultMessage,
} as const;

export const getTableConfiguration = createSelector(getLoggedInUserAccountId, (accountId) =>
	[
		accountId !== null
			? {
					title: StarTitle,
					Cell: FavoriteCell,
					sortField: FAVOURITED,
					width: 3,
					isIconOnlyHeader: isVisualRefreshEnabled() && fg('visual-refresh_drop_6'),
					ascendingSortTooltip:
						isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
							? messages.starredToUnstarredOrder
							: undefined,
					descendingSortTooltip:
						isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
							? messages.unstarredToStarredOrder
							: undefined,
				}
			: undefined,
		{
			title: messages.name,
			Cell: TitleCell,
			sortField: NAME,
			width: accountId ? 25 : 35,
			ascendingSortTooltip:
				isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? messages.aToZOrder : undefined,
			descendingSortTooltip:
				isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? messages.zToAOrder : undefined,
		},
		accountId !== null
			? {
					title: messages.owner,
					Cell: OwnerCell,
					sortField: OWNER,
					width: 19,
					ascendingSortTooltip:
						isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
							? messages.aToZOrder
							: undefined,
					descendingSortTooltip:
						isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
							? messages.zToAOrder
							: undefined,
				}
			: undefined,
		{
			title: messages.viewers,
			Cell: AccessCell,
			// eslint-disable-next-line no-nested-ternary
			width: accountId ? (isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? 19 : 19.5) : 25,
		},
		{
			title: messages.editors,
			Cell: EditCell,
			width: accountId ? 19 : 25,
		},
		{
			title: messages.popularity,
			Cell: PopularityCell,
			sortField: POPULARITY,
			width: accountId ? 9 : 15,
			ascendingSortTooltip:
				isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
					? messages.lowestToHighestOrder
					: undefined,
			descendingSortTooltip:
				isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
					? messages.highestToLowestOrder
					: undefined,
		},
		// eslint-disable-next-line no-nested-ternary
		accountId !== null
			? isVisualRefreshEnabled()
				? {
						Cell: ActionsCell,
						width: 6,
						dataTestId: 'dashboards-main.content.headerCell.actions.container',
					}
				: {
						title: messages.moreActions,
						Cell: ActionsCell,
						width: 6,
						dataTestId: 'dashboards-main.content.headerCell.actions.container',
					}
			: undefined,
	].filter(Boolean),
);

export const getTableLabel = () => messages.tableAriaLabel;

export const getResultsAnnouncerMessage = () => messages.filterResultsAnnouncement;
