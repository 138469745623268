import type { User } from '@atlassian/jira-common-directory-v2/src/model/index.tsx';
import type { SharePermission } from '@atlassian/jira-shared-types/src/share-permission.tsx';
import type { State } from '../../types.tsx';
import { getFavoriteChangeContext } from '../app/index.tsx';

export const isEmpty = (state: State): boolean => state.dashboards.order.length === 0;
export const getIds = (state: State): string[] => state.dashboards.order;
export const getTotal = (state: State): number => state.dashboards.total;
export const isLoading = (state: State): boolean => state.dashboards.isLoading;
export const isError = (state: State): boolean => state.dashboards.isError;

export const isFilterDisplayed = (state: State, id: string): boolean =>
	Boolean(state.dashboards.page[id]);
export const getName = (state: State, id: string): string => state.dashboards.page[id]?.name;
export const getOwner = (state: State, id: string): User | null => state.dashboards.page[id]?.owner;
export const getIsWritable = (state: State, id: string): boolean =>
	state.dashboards.page[id]?.isWritable;
export const getViewUrl = (state: State, id: string): string => state.dashboards.page[id]?.viewUrl;
export const getShares = (state: State, id: string): SharePermission[] =>
	state.dashboards.page[id]?.shares;
export const getEdits = (state: State, id: string): SharePermission[] =>
	state.dashboards.page[id]?.edits;
export const getSystemDashboard = (state: State, id: string): boolean =>
	state.dashboards.page[id]?.systemDashboard;

const getFavoriteChangeContextItem = (state: State, id: string) =>
	getFavoriteChangeContext(state).items.dashboards?.[id];

export const isFavorite = (state: State, id: string): boolean => {
	const itemChanged = getFavoriteChangeContextItem(state, id);
	return itemChanged ? itemChanged.value : state.dashboards.page[id]?.isFavourite;
};

export const isPending = (state: State, id: string): boolean => {
	const item = getFavoriteChangeContextItem(state, id);
	return !!(item && item.pending);
};

export const getPopularity = (state: State, id: string): number => {
	const popularityInState = state.dashboards.page[id]?.popularity;
	const isFavoriteInState = state.dashboards.page[id]?.isFavourite;
	const item = getFavoriteChangeContextItem(state, id);

	if (!item) return popularityInState;

	if (item.value && !isFavoriteInState) {
		return popularityInState + 1;
	}
	if (!item.value && isFavoriteInState) {
		return popularityInState - 1;
	}
	return popularityInState;
};

export const getChangeFavorite = (state: State) => getFavoriteChangeContext(state).changeFavorite;
