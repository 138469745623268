import FetchError, { type ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { transformValidationErrorFromServer } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const getErrorFromResponse = async (
	response: Response,
	defaultMessage: string,
): Promise<ValidationError | FetchError> => {
	let errorMessage = defaultMessage;

	try {
		const json = await response.json();
		const { errors, errorMessages } = json;

		if (errors && Object.keys(errors).length) {
			return transformValidationErrorFromServer(json);
		}
		errorMessage = errorMessages[0];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		/**/
	}

	return new FetchError(response.status, errorMessage);
};
