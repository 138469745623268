/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async.tsx';
import type { User } from '../../model/index.tsx';
import AvatarTitleCell from '../avatar-title-cell/view.tsx';

export type Props = {
	user: User | null;
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	onClick?: () => void | null | undefined;
	isDeleting?: boolean;
};

export default function UserCell(props: Props) {
	const { user, isDeleting } = props;

	if (!user) {
		return null;
	}

	const { accountId, displayName, avatarUrl } = user;
	const content = (
		<AvatarTitleCell displayName={displayName} avatarUrl={avatarUrl} isDeleting={isDeleting} />
	);

	return <AsyncProfileCardNext accountId={accountId}>{content}</AsyncProfileCardNext>;
}
