// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import View from './ui/index.tsx';

export type AppProps = {
	baseUrl: BaseUrl;
	onClick: ((e: Event) => void) | undefined;
};

const CreateButton = (props: AppProps) => (
	<AppBase id="directories.dashboards-v2.create-button" hasErrorBoundary={false}>
		<ReportErrors id="unhandled" packageName="jiraCreateDashboardsButton" teamName="jira-cosmos">
			<AppStyle>
				<View baseUrl={props.baseUrl} onClick={props.onClick} />
			</AppStyle>
		</ReportErrors>
	</AppBase>
);

CreateButton.defaultProps = {
	onClick: undefined,
};

export default CreateButton;

export type CreateButtonProps = JSX.LibraryManagedAttributes<
	typeof CreateButton,
	ComponentProps<typeof CreateButton>
>;
